<template>
  <v-dialog
    v-model="dialogEdit"
    @click:outside="$emit('update:dialogEdit', false)"
    @keydown.esc="$emit('update:dialogEdit', false)"
    max-width="1300"
    fullscreen
    scrollable
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        Editar {{ equipe_individuo.nome }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogEdit', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-8">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- Logo -->
            <v-col cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Avatar</h1> -->
              <v-card
                outlined
                class="fill-height d-flex align-center justify-center"
                style="position: relative"
              >
                <v-card flat class="pa-4">
                  <v-img
                    v-if="equipe_individuo.avatar"
                    :src="equipe_individuo.avatar"
                    contain
                  ></v-img>
                  <v-icon size="200" v-else>mdi-account</v-icon>
                </v-card>

                <v-btn
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  @click="onButtonClick"
                >
                  <input
                    accept="image/*"
                    type="file"
                    class="d-none"
                    ref="uploader"
                    @change="onFileChanged"
                  />
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-card>
            </v-col>

            <!-- Dados -->
            <v-col cols="12" md="9" class="px-9">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

              <v-row>
                <!--Nome  -->
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="equipe_individuo.nome"
                    label="Nome"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-text-field>
                </v-col>

                <!-- Responsavel -->
                <v-col cols="12" md="5" class="d-flex">
                  <v-text-field dense readonly value="Responsavel:">
                    <template v-slot:append>
                      <v-switch
                        v-model="equipe_individuo.responsavel"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                  <v-divider vertical class="pr-2 mr-2"></v-divider>
                  <v-text-field
                    class="text-right"
                    dense
                    readonly
                    value="Status:"
                  >
                    <template v-slot:append>
                      <v-switch
                        v-model="equipe_individuo.status"
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- CPF -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    v-model="equipe_individuo.cpf"
                    label="CPF"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- RG -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="equipe_individuo.rg"
                    label="RG"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- ANIVESARIO -->
                <v-col cols="12" md="4">
                  <DataField
                    label="Data Aniversário"
                    :data_sync.sync="equipe_individuo.data_aniversario"
                  />
                </v-col>

                <!--E-mail  -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="equipe_individuo.email"
                    label="E-mail"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Cargo Selected -->
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedCargo"
                    :items="cargos"
                    label="Cargo"
                    item-text="descricao"
                    item-value="id"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-combobox>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.telefone"
                    v-mask="`(##) ####-####`"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.celular"
                    v-mask="`(##) #.####-####`"
                    label="Celular"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.whatsapp"
                    v-mask="`(##) #.####-####`"
                    label="Whatsapp"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.telegram"
                    v-mask="`(##) #.####-####`"
                    label="Telegram"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cep"
                    v-mask="`#####-###`"
                    label="CEP"
                    dense
                    append-icon="mdi-magnify"
                    @keyup.enter="fetchCep"
                    @click:append="fetchCep"
                    :loading="loadingCep"
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="equipe_individuo.endereco"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Número"
                    v-model="equipe_individuo.numero"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Bairro"
                    v-model="equipe_individuo.bairro"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="equipe_individuo.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Cidade"
                    v-model="equipe_individuo.cidade"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Contatos -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="equipe_individuo.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="equipe_individuo.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="equipe_individuo.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Youtube"
                    v-model="equipe_individuo.youtube"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- Criar Acesso -->
            <v-col cols="12" v-if="!loading">
              <GerenciadorDeAcessos
                acesso_para="cliente"
                :user_id="equipe_individuo.user_id"
                :pai_id="equipe_id"
                :prop_acesso="equipe_individuo.acesso"
                @update-pai="getEquipe"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogEdit', false)"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="updateEquipe()"
          color="secondary"
          class="white--text"
          :disabled="!validForm"
        >
          SALVAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  uploadAvatar,
  putEquipe,
  fetchEquipe,
} from "@/api/gestor/cliente_equipe.js";
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchCargos } from "@/api/gestor/cargos.js";
import { buscaCep } from "@/api/geral/buscaCep.js";

export default {
  name: "EditEquipe",

  props: {
    dialogEdit: {
      type: Boolean,
      required: true,
    },
    equipe_id: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    DataField: () =>
      import("../../../../../../components/fields/DataField.vue"),
    GerenciadorDeAcessos: () =>
      import("../../../../../../components/acessos/GerenciadorDeAcessos.vue"),
  },

  data() {
    return {
      equipe_individuo: {
        avatar: "",
        nome: "",
        responsavel: false,
        cpf: "",
        rg: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        email: "",
        telefone: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        data_aniversario: "",
      },
      loading: false,
      validForm: true,
      formRules: inputRequired,
      emailRules: validaEmail,
      cep: "",
      loadingCep: false,
      selectedCargo: null,
      cargos: [],
      loadingCargos: false,
    };
  },

  watch: {
    selectedCargo() {
      if (this.selectedCargo) {
        this.equipe_individuo.cargo_id = this.selectedCargo.id;
      }
    },
  },

  computed: {
    cliente_id() {
      return this.$route.params.clienteId;
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.equipe_individuo.cep = "";
        this.equipe_individuo.cidade = "";
        this.equipe_individuo.bairro = "";
        this.equipe_individuo.uf = "";
        this.equipe_individuo.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.equipe_individuo.cep = response.cep;
            this.equipe_individuo.cidade = response.city;
            this.equipe_individuo.bairro = response.neighborhood;
            this.equipe_individuo.uf = response.state;
            this.equipe_individuo.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    getCargos() {
      this.loadingCargos = true;
      fetchCargos()
        .then((response) => {
          this.cargos = response;
          this.loadingCargos = false;
        })
        .catch(() => {
          this.loadingCargos = false;
        });
    },

    updateEquipe() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        putEquipe(this.equipe_individuo.id, this.equipe_individuo)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Equipe editado com sucesso!");
              this.$emit("fetch-equipe");
              this.$emit("update:dialogEdit", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      const fd = new FormData();
      fd.append("avatar", file, file.name);

      uploadAvatar(this.equipe_individuo.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Avatar atualizada com sucesso!");
            this.getEquipe();
          }
        })
        .catch(() => {});
    },

    getEquipe() {
      this.loading = true;
      fetchEquipe(this.equipe_id)
        .then((response) => {
          this.equipe_individuo = response;
          this.cep = this.equipe_individuo.cep;
          this.selectedCargo = this.equipe_individuo.cargo;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getEquipe();
    this.getCargos();
  },
};
</script>

<style lang="scss" scoped></style>
